
function btc2sat(btc) {
    return Math.trunc(btc * (10 ** 8)); // TODO?
}

function sat2btc(sat) {
    return sat / (10 ** 8);
}

function expandPrice(usd_price) {
    const one_usd_equals_btc = (1 / usd_price);
    const one_usd_equals_sat = btc2sat(one_usd_equals_btc);

    return {
        usd_price,
        one_usd_equals_sat,
        one_usd_equals_btc: one_usd_equals_sat * (1 / (10 ** 8)) // based on the sats for consistency
    };
}

async function getUSDPriceNow() {

    // TODO who is responsible for this kind of error handling?
    /////
    // CUANDO getUSDPriceNow falla:
    // /////
    // error:
    // SyntaxError: Unexpected token < in JSON at position 0
    //     at JSON.parse (<anonymous>)
    //     at Response.json (file:///home/ubuntu/node_modules/node-fetch/src/body.js:147:15)
    //     at processTicksAndRejections (internal/process/task_queues.js:95:5)
    //     at async Object.getUSDPriceNow (/home/ubuntu/utils.js:65:20)
    //     at async First.doTry (/home/ubuntu/class/First.js:277:30)
    //     at async Function.doAll (/home/ubuntu/class/First.js:113:13)
    // /////
    /////

    const url = `https://data.messari.io/api/v1/assets/btc/metrics/market-data`;
    const result = await (await fetch(url)).json();
    // console.log(`result NOW:`, result);
    const price_usd = result.data.market_data.price_usd;
    const last_trade_at = result.data.market_data.last_trade_at;
    return { last_trade_at, price_usd };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    btc2sat,
    sat2btc,
    expandPrice,
    getUSDPriceNow
}
// module.exports = {
//     btc2sat,
//     sat2btc,
//     expandPrice,
//     getUSDPriceNow
// }
