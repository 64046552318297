import React from 'react';

import utils from './utils';

export class HomeStart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled_button: false,
            has_db: false,
            cryptocurrencies_with_data: [],
        };

        // // This binding is necessary to make `this` work in the callback
        // this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        Promise.resolve()
            .then(() => {
                return utils.getUSDPriceNow();
            })
            .then((response) => {
                this.setState((state, props) => ({
                    response: response
                }));
            });
    }

    render() {

        const response = this.state.response;

        let buttonAndListElement;
        if (!response) {
            buttonAndListElement = (
                <div>
                </div>
            );
        }
        else { // response
            const price_plus_now = utils.expandPrice(response.price_usd);
            
            buttonAndListElement = (
                <div>
                    1 BTC = ${Math.trunc(response.price_usd).toLocaleString()}
                    &nbsp;[{response.last_trade_at.split('.')[0]}Z]
                    {/* ${Math.trunc(response.price_usd).toLocaleString()} / BTC */}
                    <br />
                    <br />
                    $1 = {price_plus_now.one_usd_equals_sat.toLocaleString()} SAT = {price_plus_now.one_usd_equals_btc.toLocaleString(undefined, { minimumFractionDigits: 8, maximumFractionDigits: 8 })} BTC
                </div>
            );
        }

        return buttonAndListElement;
    }

}
