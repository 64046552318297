import logo from './logo512.png';
// import logo from './logo.svg';
import './App.css';

import { useTranslation } from 'react-i18next';
// import { useTranslation, Trans } from 'react-i18next';

import { HomeStart } from './HomeStart';

const lngs = {
  es: { nativeName: 'Español' },
  en: { nativeName: 'English' }
};

function App() {
  const { t, i18n } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <p>
          {/* ACUMULA bitcoin */}
          {t('acumulaBitcoin')}
        </p>

        {/* <div>{t('simpleContent')}</div> */}
        <HomeStart />

        <p>
          <div>
            {Object.keys(lngs).map((lng) => (
              <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                {lngs[lng].nativeName}
              </button>
            ))}
          </div>
          <a
            className="App-link"
            href="https://learn.saylor.org/course/PRDV151"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* APRENDE Bitcoin */}
            {t('aprendeBitcoin')}
          </a>
        </p>

      </header>
    </div>
  );
}

export default App;
